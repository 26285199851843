import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart/cart.component';
import { CurPipe } from './cur.pipe';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CartComponent, CurPipe],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  exports: [CartComponent]
})
export class CartModule { }
export * from './cart.service'
export * from './cart-item'
export * from './product'
export * from './cur.pipe'
