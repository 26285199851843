import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service'
import {ActivatedRoute, Router } from '@angular/router';
import { Request } from '../../core/request';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HcpFormComponent } from '../hcp-form/hcp-form.component';
import { Hcp, Provider, HcpHours, HcpService } from '../../core/hcp';


@Component({
  selector: 'new-hcp',
  templateUrl: './new-hcp.component.html',
  styleUrls: ['./new-hcp.component.css']
})
export class NewHcpComponent implements OnInit {
  request$: Request
  request_id: string
  hcp_uid: string

  constructor(
    public auth: AuthService,
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.route.params.subscribe(routeParams => {
        this.request_id = routeParams['request_id'];
    });

    const requestDoc = this.afs.doc<Request>(`requests/${this.request_id}`).valueChanges();
    requestDoc.subscribe(value => {
      this.request$ = value
    });

  }

  ngOnInit() {
  }

  googleLogin() {
    let promises:Promise<any>[] = [];
    promises.push(this.auth.googleLogin(this.request$.uid, true));
    promises.push(this.createHcpProfile());
    promises.push(this.createProvider());
    promises.push(this.createProvider());
    promises.push(this.createHours());
    promises.push(this.createServices());
    Promise.all(promises).then(() => {
      this.updateRequest();
    });

  }

  updateRequest() {
    const requestRef: AngularFirestoreDocument<Request> = this.afs.doc(`requests/${this.request_id}`);
    const data = {
      used: true,
      reviewed: false,
    };
    console.log("setting requestRef...");
    requestRef.set(data, { merge: true });
    this.router.navigateByUrl(`edit/${this.request$.uid}`);
  }

  createHcpProfile(): Promise<void> {
    const hcpRef: AngularFirestoreDocument<Hcp> = this.afs.doc(
      `hcps/${this.request$.uid}`
    );
    const data = {
      hcp_uid: this.request$.uid,
      mStatement: "<p>Place your <strong>MISSION STATEMENT</strong> here!</p>",
      practiceName: "Your Practice Name!",
      siteName: this.request$.siteName,
      email: this.request$.email,
      lat: 33.8613599802915,
      lng: -117.8251420197085,
      logoURL: "./assets/md-theme/img/logo.png",
      bannerURL: "./assets/stock-banner.jpg",
      photoUrl: "./assets/stock-headshot.jpg",
      phone: "555 555 5555",
      address: "1640 Riverside Drive",
      city: "Hill Valley",
      state: "CA",
    };
    return hcpRef.set(data);
  }

  createProvider() {
    const data = {
      name: "Example Provider",
      headshotURL: "./assets/stock-pa.jpg",
      title: "example title",
      bio: "An awesome bio!",
      providerID: this.afs.createId(),
      createdAt: Date.now(),
    }
    const providerRef: AngularFirestoreDocument<Provider> = this.afs.doc(
      `hcps/${this.request$.uid}/providers/${data.providerID}`
    );
    return providerRef.set(data);
  }

  createHours() {
    const weekdays: HcpHours = {
      days: "Monday - Friday",
      hours: "9am - 5:30pm",
      hoursID: this.afs.createId(),
      createdAt: Date.now(),
    }
    const weekends: HcpHours = {
      days: "Saturday - Sunday",
      hours: "9am - 3:30pm",
      hoursID: this.afs.createId(),
      createdAt: Date.now(),
    }
    const weekdayRef: AngularFirestoreDocument<HcpHours> = this.afs.doc(
      `hcps/${this.request$.uid}/hours/${weekdays.hoursID}`
    );
    const weekendRef: AngularFirestoreDocument<HcpHours> = this.afs.doc(
      `hcps/${this.request$.uid}/hours/${weekends.hoursID}`
    );
    weekdayRef.set(weekdays);
    return weekendRef.set(weekends);
  }

  createServices() {
    const firstService: HcpService = {
      name: "Primary Care",
      description: "Our patient’s care is our top priority. We strive to offer the best care possible for you and your whole family. Under the direction of our world class doctors and advisement of our amazing staff, we have an experienced staff of caring providers and nurses who go the extra mile for our patients.",
      icon: "flaticon-healthcare6",
      serviceID: this.afs.createId(),
      createdAt: Date.now(),
    }
    const secondService: HcpService = {
      name: "Pain Management",
      description: "Pain management can be simple or complex, depending on the cause of the pain. An example of pain that is typically less complex would be nerve root irritation from a herniated disc with pain radiating down the leg.",
      icon: "flaticon-pill",
      serviceID: this.afs.createId(),
      createdAt: Date.now(),
    }
    const firstRef: AngularFirestoreDocument<HcpService> = this.afs.doc(
      `hcps/${this.request$.uid}/services/${firstService.serviceID}`
    );
    const secondRef: AngularFirestoreDocument<HcpService> = this.afs.doc(
      `hcps/${this.request$.uid}/services/${secondService.serviceID}`
    );
    firstRef.set(firstService);
    return secondRef.set(secondService);
  }

}

// const requestRef: AngularFirestoreDocument<any> = this.afs.doc(
//   `requests/${this.request_id}`
// );
// this.request$ = requestRef.get()
//   .then((docSnapshot) => {
//     if (docSnapshot.exists) {
//       return this.afs.doc<Request>(`users/${this.request_id}`).valueChanges();
//     } else {
//       return Observable.of(null);
//     }
//   });
