import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { first, switchMap } from 'rxjs/operators';
import { Hcp } from '../../core/hcp';
import { Subject, Observable, of } from 'rxjs';
import { Request } from '../../core/request';

@Component({
  selector: 'app-changes',
  templateUrl: './changes.component.html',
  styleUrls: ['./changes.component.css']
})
export class ChangesComponent implements OnInit {
  new_profiles$: Observable<any[]>
  edited_profiles$: Observable<any[]>
  disabled_profiles$: Observable<any[]>


  constructor(
    public auth: AuthService,
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router,
    private afAuth: AngularFireAuth,
    ) {

      this.new_profiles$ = afs.collection('requests', ref => ref.where('reviewed', '==', false)).valueChanges()
      this.edited_profiles$ = afs.collection('hcps', ref => ref.where('edited', '==', true)).valueChanges()
      this.disabled_profiles$ = afs.collection('hcps', ref => ref.where('disabled', '==', true)).valueChanges()
   }

  ngOnInit() {
  }

  new_reviewed(reqId: string){
    const requestRef: AngularFirestoreDocument<Request> = this.afs.doc(`requests/${reqId}`);
    const data = {
      reviewed: true,
    };
    console.log("setting requestRef...");
    requestRef.set(data, { merge: true });
  }

  editded_reviewed(uid: string){
    const hcpRef: AngularFirestoreDocument = this.afs.doc(`hcps/${uid}`);
    const data = {
      edited: false,
      last_edit: null,
    }
    hcpRef.set(data, { merge: true});
  }

  disable_edited(uid: string){
    const hcpRef: AngularFirestoreDocument = this.afs.doc(`hcps/${uid}`);
    const data = {
      edited: false,
      last_edit: null,
      disabled: true,
    }
    hcpRef.set(data, { merge: true});
  }

  enable_disabled(uid:string){
    const hcpRef: AngularFirestoreDocument = this.afs.doc(`hcps/${uid}`);
    const data = {
      disabled: false,
    }
    hcpRef.set(data, { merge: true});
  }

  edit_disabled(uid:string) {
    this.router.navigateByUrl(`/edit/${uid}`)
  }

  redirect(sitename: string) {
    this.router.navigateByUrl(`/${sitename}`);
  }
}
