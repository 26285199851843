import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.css']
})
export class FeaturedComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit() {
  }
}
