import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';
import { Request } from '../../core/request';
import { Observable } from 'rxjs/internal/Observable';
import { AngularFireUploadTask, AngularFireStorage } from 'angularfire2/storage';
import { tap, finalize } from 'rxjs/operators';
import { EditorModule}  from 'primeng/editor';



@Component({
  selector: 'hcp-form',
  templateUrl: './hcp-form.component.html',
  styleUrls: ['./hcp-form.component.css']
})
export class HcpFormComponent implements OnInit {
  hcpForm: FormGroup;
  request_id: string;
  request$: Request;
  bannerURL: string;
  headshotURL: string;
  testText: string;
  formValue: any;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    ) {
      this.route.params.subscribe(routeParams => {
          this.request_id = routeParams['request_id'];
      });

      const requestDoc = this.afs.doc<Request>(`requests/${this.request_id}`).valueChanges();
      requestDoc.subscribe(value => {
        this.request$ = value
      });

    }

  ngOnInit() {

    this.hcpForm = this.fb.group({
      pName: [this.request_id,
        Validators.required],
      h_services: this.fb.array([]),
      missionStatement: '',
      banner: null,
      headshot: null,
      test2: [this.testText],
    })
  }

  get h_serviceForms() {
    return this.hcpForm.get('h_services') as FormArray
  }

  addHService() {

    const h_service = this.fb.group({
      name: [],
      description: [],
      hours: [],
    })

    this.h_serviceForms.push(h_service);
  }

  deleteService(i) {
  this.h_serviceForms.removeAt(i)
}

  submitHandler() {
    console.log(this.hcpForm.value);


  }

  missionHandler() {
    console.log("test");
  }

  startUpload(event: FileList, part: string) {
    // The File object
    const file = event.item(0)


    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ')
      return;
    }

    // The storage path
    const path = `${part}/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { practice:  'todo: add practice name' };

    // Reference to file
    const fileRef = this.storage.ref(path);

    // The main task
    const task = this.storage.upload(path, file, { customMetadata })

    // Progress monitoring
    var percentage = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        const downloadURL = fileRef.getDownloadURL()
        downloadURL.subscribe(url => {
          switch(part) {
            case "banner": {
              this.bannerURL = url;
              break;
            }
            case "headshot": {
              this.headshotURL = url;
              break;
            }
          }
        });
    })
  )
  .subscribe();
  }
}
