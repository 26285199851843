import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule } from '@angular/forms' // Prebuilt Forms module

import { UserProfileComponent } from './user-profile/user-profile.component';
import { HcpProfileComponent } from './hcp-profile/hcp-profile.component';
import { NewHcpComponent } from './new-hcp/new-hcp.component';
import { HcpFormComponent } from './hcp-form/hcp-form.component';
import { EditorModule }  from 'primeng/editor';
import { ToastModule } from 'primeng/toast';
import { FormsModule } from '@angular/forms';
import { HcpEditComponent } from './hcp-edit/hcp-edit.component';
import { ProductsComponent } from './products/products.component';
import { AppointmentModalComponent } from './appointment-modal/appointment-modal.component';
import { AgmCoreModule } from '@agm/core';
import { SearchComponent } from './search/search.component';
import { FeaturedComponent } from './featured/featured.component';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ChangesComponent } from './changes/changes.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';

import { CartModule } from '../cart/cart.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    EditorModule,
    InputSwitchModule,
    ToastModule,
    FormsModule,
    CartModule,
    BrowserAnimationsModule,
    ToasterModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDhMFo64zAUvArTJLonpDsuPuVOD1BenG4'
    }),
  ],
  declarations: [UserProfileComponent, HcpProfileComponent, NewHcpComponent, HcpFormComponent, HcpEditComponent, ProductsComponent, AppointmentModalComponent, SearchComponent, FeaturedComponent, ChangesComponent, AdminLoginComponent],
  exports: [UserProfileComponent, HcpProfileComponent],
})
export class UiModule { }
