import { Component, OnInit, ViewChild } from '@angular/core';
import { CartService } from '../cart.service';
import { SponsorService } from '../../core/sponsor.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { CountryService } from '../../core/country.service';
import { NgbModal, NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  @ViewChild('noRetailSpons') noSpons: any;
  private cc: string = 'US';
  private curCode: string = null;
  private modalRef: NgbModalRef;
  private sponsForm: FormGroup;

  constructor(
    private cartServ: CartService,
    private spons: SponsorService,
    private router: Router,
    // private country: CountryService,
    private modalService: NgbModal,
  ) {
    // this.country.country.subscribe((country)=>{
    //   if (country) {
    //     this.cc=country.cc
    //     this.curCode=country.ctype;
    //   }
    // })
  }

  swappyMcSwapFace(){
    this.cartServ.showCart = !this.cartServ.showCart
  }

  ngOnInit() {
  }

  goToProducts() {
    this.router.navigateByUrl('/products')
    this.cartServ.showCart = !this.cartServ.showCart
  }

  checkout() {
    var elink = 'https://www.cs4000.net/CareBeyond/purch.asp?sponsorid='
    this.spons.sponsor.subscribe(spons => {
      if (spons.id) {
        console.log('Proceed!')
        this.cartServ.link.subscribe(link => {
          elink = elink + spons.id + link
          // console.log(elink);
          window.location.href=elink;
        }).unsubscribe()
      }
    }).unsubscribe()
  }

}
