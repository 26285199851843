import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireUploadTask, AngularFireStorage } from 'angularfire2/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { FormsModule } from '@angular/forms';
import { tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { EditorModule}  from 'primeng/editor';
import { Hcp, HcpService, Provider, HcpHours } from '../../core/hcp';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { take } from 'rxjs/operator/take';
import { AuthService } from '../../core/auth.service';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import {InputSwitchModule} from 'primeng/inputswitch';


@Component({
  selector: 'hcp-edit',
  templateUrl: './hcp-edit.component.html',
  styleUrls: ['./hcp-edit.component.css'],
  providers: [MessageService]
})
export class HcpEditComponent implements OnInit {
	headshotURL: string;
	bannerURL: string;
	hcp_uid: string;
  siteName: string;
  hcp$: Hcp;
  hcpForm: FormGroup;
  missionStatement: string;
  logoURL:  string;
  tagline:  string;
  serviceIconOptions = [
                      'flaticon-tooth9',
                      'flaticon-tooth12',
                      'flaticon-syrup1',
                      'flaticon-scalpel3',
                      'flaticon-pipette2',
                      'flaticon-pipette1',
                      'flaticon-pill',
                      'flaticon-pharmacy5',
                      'flaticon-medical51',
                      'flaticon-medical14',
                      'flaticon-medical109',
                      'flaticon-lungs4',
                      'flaticon-heart8',
                      'flaticon-healthcare6',
                      'flaticon-eye64',
                      'flaticon-brain13',
                      'flaticon-baby137',
                      'flaticon-acrobat1'
                      ];
  headshotMap: {[providerID: string]: string; } = {};
  serviceIDs: [string] = [''];
  providerIDs: [string] = [''];
  hoursIDs: [string] = [''];


  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private fm: FormsModule,
    private fb: FormBuilder,
    public auth: AuthService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {

    this.route.params.subscribe(routeParams => {
      this.hcp_uid = routeParams['hcp_uid'];
    });

    const hcpDoc = this.afs.doc<Hcp>(`hcps/${this.hcp_uid}`).valueChanges();
    hcpDoc.subscribe(value => {
      this.hcp$ = value

      this.bannerURL = this.hcp$.bannerURL;
      this.headshotURL = this.hcp$.photoUrl;
      this.logoURL = this.hcp$.logoURL;




      this.hcpForm = this.fb.group({
        siteName:         [this.hcp$.siteName, Validators.required],
        practiceName:     this.hcp$.practiceName,
        mlm:              this.hcp$.mlm,
        health_services:  this.fb.array([]),
        missionStatement: this.hcp$.mStatement,
        providers:        this.fb.array([]),
        hours:            this.fb.array([]),
        banner:           null,
        headshot:         null,
        logo:             null,
        tagline:          this.hcp$.tagline,
        phone:            this.hcp$.phone,
        email:            this.hcp$.email,
        address:          this.hcp$.address,
        address2:         this.hcp$.address2,
        city:             this.hcp$.city,
        state:            this.hcp$.state,
        zip:              this.hcp$.zip,
      })
    });

    this.hcpForm = this.fb.group({
    })

    const serviceCollection = this.afs.collection<HcpService>(`hcps/${this.hcp_uid}/services`, ref =>
      ref.orderBy("createdAt"));
    const services = serviceCollection.valueChanges();
    services.subscribe(val => {
      for (let service of val) {
        if (this.serviceIDs.includes(service.serviceID)) {
          continue;
        }
        const health_service = this.fb.group({
          name: service.name,
          description: service.description,
          icon: service.icon,
          serviceID: service.serviceID,
          createdAt: service.createdAt,
          open: true,
          view: true,
        })
        this.health_serviceForms.push(health_service);
        this.serviceIDs.push(service.serviceID);
      }
    });



    const providerCollection = this.afs.collection<Provider>(`hcps/${this.hcp_uid}/providers`, ref =>
      ref.orderBy("createdAt"));
    const providers = providerCollection.valueChanges();
    providers.subscribe(val => {
      for (let provider of val) {
        if (this.providerIDs.includes(provider.providerID)) {
          continue;
        }
        const health_provider = this.fb.group({
          name: provider.name,
          headshot: null,
          headshotURL: provider.headshotURL,
          title: provider.title,
          bio: provider.bio,
          providerID: provider.providerID,
          createdAt:  provider.createdAt,
          open: true,
          view: false,
        })
        this.providerForms.push(health_provider);
        this.headshotMap[provider.providerID] = provider.headshotURL;
        this.providerIDs.push(provider.providerID);
      }
    });

    const hoursCollection = this.afs.collection<HcpHours>(`hcps/${this.hcp_uid}/hours`, ref =>
      ref.orderBy("createdAt"));
    hoursCollection.valueChanges().subscribe(val => {
      for (let hours of val) {
        if (this.hoursIDs.includes(hours.hoursID)) {
          continue;
        }
        const hours_form = this.fb.group({
          days: hours.days,
          hours: hours.hours,
          hoursID: hours.hoursID,
          createdAt: hours.createdAt,
          open: true,
        })
        this.hoursForms.push(hours_form);
        this.hoursIDs.push(hours.hoursID);
      }
    });
  }


  get health_serviceForms() {
    return this.hcpForm.get('health_services') as FormArray
  }

  get providerForms() {
    return this.hcpForm.get('providers') as FormArray
  }

  get hoursForms() {
    return this.hcpForm.get('hours') as FormArray
  }

  addHService() {
    const health_service = this.fb.group({
      name: "",
      description: "",
      icon: "",
      serviceID: this.afs.createId(),
      createdAt: Date.now(),
      open: true,
    })
    this.health_serviceForms.push(health_service);
    this.serviceIDs.push(health_service.get("serviceID").value);
    console.log(health_service.get("createdAt").value);
  }

  addProvider() {
    const health_provider = this.fb.group({
      name: "",
      headshot: null,
      headshotURL: "",
      title: "",
      bio: "",
      providerID: this.afs.createId(),
      createdAt: Date.now(),
      open: true,
    })
    this.providerForms.push(health_provider);
    this.providerIDs.push(health_provider.get("providerID").value);
  }

  addHours() {
    const hours_form = this.fb.group({
      days: "",
      hours: "",
      hoursID: this.afs.createId(),
      createdAt: Date.now(),
      open: true,
    })
    this.hoursForms.push(hours_form);
    this.hoursIDs.push(hours_form.get("hoursID").value);
  }

  deleteService(i) {
    const removeID = this.health_serviceForms.at(+i).value.serviceID;
    const serviceRef: AngularFirestoreDocument<HcpService> = this.afs.doc(`hcps/${this.hcp_uid}/services/${removeID}`);
    serviceRef.delete();
    this.health_serviceForms.removeAt(i);
  }

  deleteProvider(i) {
    const removeID = this.providerForms.at(+i).value.providerID;
    const providerRef: AngularFirestoreDocument<Provider> = this.afs.doc(`hcps/${this.hcp_uid}/providers/${removeID}`);
    providerRef.delete();
    this.providerForms.removeAt(i);
  }

  deleteHours(i){
    const removeID = this.hoursForms.at(+i).value.hoursID;
    const hoursRef: AngularFirestoreDocument<HcpHours> = this.afs.doc(`hcps/${this.hcp_uid}/hours/${removeID}`);
    hoursRef.delete();
    this.hoursForms.removeAt(i);
  }

  submitHandler() {
    if (this.hcpForm.get('siteName').value == "") {
      return null;
    }
    const hcpRef: AngularFirestoreDocument<Hcp> = this.afs.doc(`hcps/${this.hcp_uid}`);
    const data: Hcp = {
      hcp_uid: this.hcp_uid,
      siteName: this.hcpForm.get('siteName').value,
      mStatement: this.hcpForm.get('missionStatement').value,
      photoUrl: this.headshotURL,
      reviewed: false,
      bannerURL: this.bannerURL,
      tagline: this.hcpForm.get('tagline').value,
      logoURL: this.logoURL,
      phone: this.hcpForm.get('phone').value,
      email: this.hcpForm.get('email').value,
      practiceName: this.hcpForm.get('practiceName').value,
      practiceNameLower: this.hcpForm.get('practiceName').value.toLowerCase(),
      mlm: this.hcpForm.get('mlm').value,
      last_edit: Date.now(),
      edited: true,
    }
    hcpRef.set(data, {merge: true})
      .then(() => this.saveComplete())

    for (let i in this.health_serviceForms.value) {
      const service = this.health_serviceForms.at(+i).value;
      const serviceRef: AngularFirestoreDocument<HcpService> = this.afs.doc(`hcps/${this.hcp_uid}/services/${service.serviceID}`);
      const data: HcpService = {
        name: service.name,
        description: service.description,
        icon: service.icon,
        serviceID: service.serviceID,
        createdAt: service.createdAt,
      }
      serviceRef.set(data, {merge: true});
    }

    for (let i in this.providerForms.value) {
      const provider = this.providerForms.at(+i).value;
      const providerRef: AngularFirestoreDocument<Provider> = this.afs.doc(`hcps/${this.hcp_uid}/providers/${provider.providerID}`);
      const data: Provider = {
        name: provider.name,
        headshotURL: this.headshotMap[provider.providerID],
        title: provider.title,
        bio: provider.bio,
        providerID: provider.providerID,
        createdAt:  provider.createdAt,
      }
      providerRef.set(data, {merge: true});
    }

    for (let i in this.hoursForms.value) {
      const hours = this.hoursForms.at(+i).value;
      const hoursRef: AngularFirestoreDocument<HcpHours> = this.afs.doc(`hcps/${this.hcp_uid}/hours/${hours.hoursID}`);
      const data: HcpHours = {
        days: hours.days,
        hours: hours.hours,
        hoursID: hours.hoursID,
        createdAt: hours.createdAt,
      }
      hoursRef.set(data, {merge: true});
    }
  }

  private saveComplete() {
    this.messageService.add({severity:'success', summary:'Saved!', detail:'Your profile has been updated'});
  }

  uploadedStarted() {
    this.messageService.add({severity:'info', summary:'Image Uploading', detail:'Your image is uploading'});
  }
  uploadDone() {
    this.messageService.add({severity:'success', summary:'Image Uploaded!', detail:'Your image has been uploaded'});
  }

  startUpload(event: FileList, part: string, providerID?: string) {
    // The File object
    const file = event.item(0)


    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ')
      return;
    }
    this.uploadedStarted();

    // The storage path
    const path = `${part}/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { practice:  'todo: add practice name' };

    // Reference to file
    const fileRef = this.storage.ref(path);

    // The main task
    const task = this.storage.upload(path, file, { customMetadata })

    // Progress monitoring
    var percentage = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        this.uploadDone();
        const downloadURL = fileRef.getDownloadURL()
        downloadURL.subscribe(url => {
          switch(part) {
            case "banner": {
              this.bannerURL = url;
              break;
            }
            case "headshot": {
              if (providerID) {
                this.headshotMap[providerID] = url;
              } else {
                this.headshotURL = url;
              }
              break;
            }
            case "logo": {
              this.logoURL = url;
              break;
            }
          }
        });
    })
  )
  .subscribe();
  }
}
