import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppointmentData } from '../../core/appointmentData';
import { AngularFireFunctions } from 'angularfire2/functions';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';


@Component({
  selector: 'appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.css']
})
export class AppointmentModalComponent implements OnInit {
  closeResult: string;
  siteName: string;
  @Input() hcp_uid: string;
  @Input() inners: string;
  appointmentForm: FormGroup;
  modalRef: NgbModalRef;


  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public aff: AngularFireFunctions,
    private route: ActivatedRoute,
    private messageService: MessageService,
  ) {
    this.route.params.subscribe(routeParams => {
      this.siteName = routeParams['siteName'];
    });

  }

  open(content) {
    this.modalRef = this.modalService.open(content, {'centered': true,});
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(this.closeResult)
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.appointmentForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.required],
      phone: "",
      dateReq: ["", Validators.required],
      address: "",
      city: "",
      zip: "",
    });
  }

  submitHandler() {
    console.log("test");
    var data: AppointmentData = {
      hcp_email: this.localHcpEmail,
      first: this.appointmentForm.get('firstName').value,
      last: this.appointmentForm.get('lastName').value,
      dateReq: this.appointmentForm.get('dateReq').value,
      email: this.appointmentForm.get('email').value,
      phone: this.appointmentForm.get('phone').value,
      address: this.appointmentForm.get('address').value,
      city: this.appointmentForm.get('city').value,
      zip: this.appointmentForm.get('zip').value,
    };
    // this.aff.httpsCallable('appointmentEmail').call(null, data);
    this.modalRef.close("Submitted");
    this.messageService.add({severity:'success', summary: 'Request Sent!', detail:'Thanks for your submission!'});
  }

  get localHcpEmail() {
    if (localStorage.getItem(`${this.hcp_uid}`)) {
      var hcp = JSON.parse(localStorage.getItem(`${this.hcp_uid}`));
      return hcp.email
    } else if (localStorage.getItem(`${this.siteName}`)) {
      var hcp = JSON.parse(localStorage.getItem(`${this.siteName}`));
      return hcp.email
    }
  }
}
