import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { switchMap, startWith, tap } from 'rxjs/operators';
import { User } from './user';
import { of } from 'rxjs';
import {ActivatedRoute} from '@angular/router';


@Injectable()
export class AuthService {
  user$: Observable<User>;
  hcpuid: string;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`md-users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      }),
      tap(user => {
        if (user) {
          localStorage.setItem('user', JSON.stringify(user))
        }
      }),
      startWith(JSON.parse(localStorage.getItem('user')))
    );
  }

  ////// OAuth Methods /////
  googleLogin(hcp_uid: string, isHCP = false) {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider, hcp_uid, isHCP);
  }

  githubLogin(hcp_uid: string, isHCP?: boolean) {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.oAuthLogin(provider, hcp_uid, isHCP);
  }

  facebookLogin(hcp_uid: string, isHCP = false) {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider, hcp_uid, isHCP);
  }

  twitterLogin(hcp_uid: string, isHCP?: boolean) {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.oAuthLogin(provider, hcp_uid, isHCP);
  }

  private oAuthLogin(provider: any, hcp_uid: string, isHCP?: boolean) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(credential => {
        if (credential.additionalUserInfo.isNewUser == true) {
          var userData: User = {
            uid: credential.user.uid,
            email: credential.user.email,
            name: credential.user.displayName,
            roles: { client: true },
          }
          if (isHCP) {
            userData.roles = { client: true, hcp: true}
          }
          return this.updateUserData(userData,hcp_uid);
        }
      })
      .catch(error => this.handleError(error));
  }

  //// Anonymous Auth ////
  // anonymousLogin() {
  //   return this.afAuth.auth
  //     .signInAnonymously()
  //     .then(credential => {
  //       return this.updateUserData(credential.user);
  //     })
  //     .catch(error => {
  //       this.handleError(error);
  //     });
  // }

  //// Email/Password Auth ////
  emailSignUp(email: string, password: string,name: string, hcp_uid: string) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(credential => {
        const userData = credential.user;
        userData.displayName = name;
        return this.updateUserData(credential.user, hcp_uid);
      });
      // .catch(error => this.handleError(error));
  }

  emailLogin(email: string, password: string, hcp_uid: string) {
    console.log("Signing into: ",email);
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(credential => {
        console.log(credential.user);
        // return this.updateUserData(credential.user, hcp_uid);
      })
      .catch(error => this.handleError(error));
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const fbAuth = firebase.auth();

    return fbAuth
      .sendPasswordResetEmail(email)
      .catch(error => this.handleError(error));
  }

  signOut() {
    localStorage.removeItem("user");
    this.afAuth.auth.signOut()
  }

  // If error, console log
  private handleError(error: Error) {
    console.error(error);
  }

  // Sets user data to firestore after succesful login
  private updateUserData(user, hcp_uid: string) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `md-users/${user.uid}`
    );
    const data: User = {
      uid: user.uid,
      email: user.email || null,
      name: user.name || user.displayName,
      roles: user.roles || { client: true },
      hcp_uid: hcp_uid || null
    };
    return userRef.set(data, { merge: true });
  }

  // ROLE BASED AUTHORIZATION
  canEdit(user: User, profile: string): boolean {
    const allowed = ['admin', 'hcp']
    return this.checkAuthorization(user, profile, allowed);
  }

  isAdmin(user: User) {
    const allowed = ['admin']
    return this.checkAuthorization(user, "NaN", allowed);
  }

  private checkAuthorization(user: User, profile: string, allowedRoles: string[]): boolean {
    console.log("checking!");
    if (!user) {
      return false
    }
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        if (role == 'hcp') {
          if (user.hcp_uid == profile)
          return true
        } else return true
      }
    }
    return false
  }

}
