import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Hcp } from '../../core/hcp';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { SponsorService } from '../../core/sponsor.service';
import { CartService } from '../../cart/cart.service';


@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  @Input() hcp_uid$: Observable<string>;
  products$: Observable<any[]>;
  carepack$: Observable<any[]>;
  patches$: Observable<any[]>;
  cc: string = 'US'

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private sponsor: SponsorService,
    private cart: CartService,
  ) {
    // if (this.hcp_uid$){
    //   this.hcp_uid$.subscribe((id) => {
    //     this.sponsor.setSponsor(id)
    //   })
    // }
    this.loadCarePacks()
    this.loadProducts()
    this.loadPatches()
  }

  ngOnInit() {
  }

  async loadCarePacks() {
    // this.loadingBar.start()
    var careRef = this.db.collection('products', ref => ref.where('otg_variant', '==', true)
      .where('lcc', 'array-contains', this.cc));
    this.carepack$ = careRef.valueChanges()
    // this.loadingBar.complete()
  }

  async loadProducts() {
    // const delay = ms => new Promise(res => setTimeout(res, ms));
    // this.loadingBar.start()
    var productsRef = this.db.collection('products', ref => ref.where('products_page', '==', true)
      .where('lcc', 'array-contains', this.cc));
    this.products$ = productsRef.valueChanges()
    this.products$.subscribe((produs) => {
      // this.loadingBar.complete()
    })

  }

  async loadPatches() {
    // this.loadingBar.start()
    var patchRef = this.db.collection('products', ref => ref.where('patch_product', '==', true)
      .where('lcc', 'array-contains', this.cc));
    this.patches$ = patchRef.valueChanges()
    // this.loadingBar.complete()
  }

}
