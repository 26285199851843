import { Hcp, HcpService, Provider, HcpHours } from '../../core/hcp'
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../core/auth.service'
import {ActivatedRoute} from '@angular/router';
import { Request } from '../../core/request'
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { switchMap, tap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { AngularFireFunctions } from 'angularfire2/functions';
import { AppointmentData } from '../../core/appointmentData';
import { Http } from '@angular/http';
import { AgmCoreModule } from '@agm/core';
import { Title } from '@angular/platform-browser';
import { SponsorService } from '../../core/sponsor.service';


@Component({
  selector: 'hcp-profile',
  templateUrl: './hcp-profile.component.html',
  styleUrls: ['./hcp-profile.component.css']
})
export class HcpProfileComponent implements OnInit {
  @ViewChild('products') private myproductsContainer: ElementRef;
  hcp_uid: string;
  hcp_uid$: Observable<string> = of(this.hcp_uid);
  hcp$: Observable<Hcp>;
  siteName: string;
  services$: Observable<HcpService[]>;
  providers$: Observable<Provider[]>;
  hours$: Observable<HcpHours[]>;
  uidExists: boolean;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    public aff: AngularFireFunctions,
    private titleService: Title,
    private sponsor: SponsorService,
  ) {
    this.route.params.subscribe(routeParams => {
      this.siteName = routeParams['siteName'];
    });


    var uidRef = this.afs.doc<Hcp>(`hcps/${this.siteName}`).snapshotChanges();
    uidRef.subscribe(val => {
      console.log("checking if uid exists");
      this.uidExists = val.payload.exists;
    });



    const hcpCollection = this.afs.collection<Hcp>('hcps', ref =>
      ref.where('siteName', '==', this.siteName).limit(1))
    this.hcp$ = hcpCollection.valueChanges().pipe(
      switchMap((value, i) => {
        if (value[0]) {
          console.log(value)
          this.hcp_uid = value[0].hcp_uid;
          this.hcp_uid$ = of(this.hcp_uid);
          this.services$ = this.services;
          this.providers$ = this.providers;
          this.hours$ = this.hours;
          this.titleService.setTitle(value[0].practiceName);
          this.sponsor.setSponsor(this.hcp_uid);
          return this.afs.doc<Hcp>(`hcps/${value[0].hcp_uid}`).valueChanges();
        } else {
          console.log("if in switchMap");
          if (this.uidExists) {
            console.log("Using ID to get HCP");
            this.hcp_uid = this.siteName;
            this.hcp_uid$ = of(this.siteName);
            this.services$ = this.services;
            this.providers$ = this.providers;
            this.hours$ = this.hours;
            this.sponsor.setSponsor(this.siteName);
            return this.afs.doc<Hcp>(`hcps/${this.siteName}`).valueChanges();
          } else {
            console.log("returning null");
            return of(null);
          }
          // if (this.uidRefExists()) {
          //   console.log("Using ID to get HCP");
          //   this.hcp_uid = this.siteName;
          //   this.hcp_uid$ = Observable.of(this.siteName);
          //   this.services$ = this.services;
          //   this.providers$ = this.providers;
          //   this.hours$ = this.hours;
          //   return this.afs.doc<Hcp>(`hcps/${this.siteName}`).valueChanges();
          // } else {
          //   console.log("Null Observable HCP");
          //   return Observable.of(null);
          // }
        }
      }),
      tap(value => {
        if (value) {
          localStorage.setItem(`${this.siteName}`, JSON.stringify(value))
        }
      }),
      startWith(this.localHCP)
    );


    // const hcpDoc = this.afs.doc<Hcp>(`hcps/${this.siteName}`);
    // this.hcp$ = hcpDoc.valueChanges().pipe(
    //   switchMap(hcp => {
    //     if (hcp) {
    //       return this.afs.doc<Hcp>(`hcps/${this.hcp_uid}`).valueChanges();
    //     } else {
    //       return Observable.of(null);
    //     }
    //   }),
    //   tap(hcp => {
    //     if (hcp) {
    //       localStorage.setItem(`${this.hcp_uid}`, JSON.stringify(hcp))
    //     }
    //   }),
    //   startWith(this.localHCP)
    // );
  }

  ngOnInit() {
  }

  get services() {
    const serviceCollection = this.afs.collection<HcpService>(`hcps/${this.hcp_uid}/services`, ref =>
      ref.orderBy("createdAt"));
    return serviceCollection.valueChanges();
  }

  get providers() {
    const providerCollection = this.afs.collection<Provider>(`hcps/${this.hcp_uid}/providers`, ref =>
      ref.orderBy("createdAt"));
    return providerCollection.valueChanges();
  }

  get hours() {
    const hoursCollection = this.afs.collection<HcpHours>(`hcps/${this.hcp_uid}/hours`, ref =>
      ref.orderBy("createdAt"));
    return hoursCollection.valueChanges();
  }

  get localHCP() {
    if (localStorage.getItem(`${this.siteName}`)) {
      const hcp = JSON.parse(localStorage.getItem(`${this.siteName}`));
      this.hcp_uid = hcp.hcp_uid;
      this.hcp_uid$ = of(this.hcp_uid);
      this.titleService.setTitle(hcp.practiceName);
      // this.services$ = this.services;
      // this.providers$ = this.providers;
      // this.hours$ = this.hours;
      return hcp
    }
    return of(null);
  }

  public productScroll() {
    this.myproductsContainer.nativeElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
