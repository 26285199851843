import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HcpProfileComponent } from './ui/hcp-profile/hcp-profile.component'
import { NewHcpComponent } from './ui/new-hcp/new-hcp.component'
import { HcpEditComponent } from './ui/hcp-edit/hcp-edit.component';
import { SearchComponent } from './ui/search/search.component';
import { AuthGuard } from './core/auth.guard';
import { AdminGuard } from './core/admin.guard';
import { ChangesComponent } from './ui/changes/changes.component';


const routes: Routes = [
  { path: '', component: SearchComponent },
  { path: 'admin/profile_changes', component: ChangesComponent, canActivate: [AdminGuard], },
  { path: ':siteName', component: HcpProfileComponent },
  { path: 'new/:request_id', component: NewHcpComponent },
  { path: 'edit/:hcp_uid', component: HcpEditComponent, canActivate: [AuthGuard], },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
