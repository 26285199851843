import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      var hcp_uid = next.params['hcp_uid']

      return this.auth.user$.pipe(
        take(1),
        map(user => user && this.auth.canEdit(user, hcp_uid) ? true : false),
        tap(canView => {
          if (!canView) {
            console.error('Access denied. You must have permission to edit')
            this.router.navigateByUrl(`/`)
          }
        })
      );
  }

}
