import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
// import { CookieService } from 'angular2-cookie/core';
import { CartItem } from './cart-item';
import { Product } from './product';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  showCart: boolean = false;
  private _cart: BehaviorSubject<CartItem[]> = new BehaviorSubject([])
  private _link: BehaviorSubject<string> = new BehaviorSubject('')
  private _price: BehaviorSubject<number> = new BehaviorSubject(0)
  private _productDic: BehaviorSubject<{[sku: string]: Product} | null> = new BehaviorSubject(null)
  private _showCart: BehaviorSubject<boolean> = new BehaviorSubject(true)
  private _size: BehaviorSubject<number> = new BehaviorSubject(0)


  constructor(
    // private cookie: CookieService,
    db: AngularFirestore,
  ) {
    // this.getCartCookie()
    var productDic = {}
    var products$ = db.collection<Product>('products').valueChanges();
    products$.subscribe((products) => {
      for (let product of products) {
        productDic[product.productID] = product
        // console.log()
      }
      this._productDic.next(productDic)
      this.cartChange();
    });
  }

  // <editor-fold> GETTERS: cart price productDic show
  get cart() {
    return this._cart.asObservable();
  }

  get price() {
    return this._price.asObservable();
  }

  get productDic() {
    return this._productDic.asObservable();
  }

  get link() {
    return this._link.asObservable();
  }

  get show() {
    return this._showCart.asObservable();
  }

  get size() {
    return this._size.asObservable();
  }
  // </editor-fold>


  // <editor-fold> Cookies: getCartCookie() setCartCookie()
  // private getCartCookie() {
  //   var cart_tmp: CartItem[] = []
  //   const cartString = this.cookie.get('CartString')
  //   if (cartString) {
  //     const cartArray = cartString.split('|')
  //     for (let item of cartArray) {
  //       var iarray = item.split(',')
  //       cart_tmp.push({sku: iarray[0], quantity: Number(iarray[1])})
  //     }
  //
  //     this._cart.next(cart_tmp)
  //   } else {
  //     // console.log('No cart cookie')
  //   }
  // }

  // private setCartCookie() {
  //   var cart_string = ''
  //   const tmp_cart = this._cart.getValue()
  //   if(tmp_cart.length > 0) {
  //     for (let item of tmp_cart){
  //       cart_string += item.sku +','+item.quantity +'|'
  //     }
  //     cart_string = cart_string.slice(0,-1)
  //     this.cookie.put('CartString', cart_string, {'domain':'carebeyond.com'})
  //   } else {
  //     // console.log('trying to remove')
  //     // this.cookie.remove('CartString', {'domain':'carebeyond.com'})
  //   }
  // }

  // </editor-fold>

  addItem(sku: string, quantity: number) {
    const item: CartItem = { sku: sku, quantity: quantity }
    var tmp_cart = this._cart.getValue()
    if(tmp_cart.length > 0) {
      var flag = 0
      for (let i in tmp_cart){
        if (sku === tmp_cart[i].sku){
          tmp_cart[i].quantity += quantity
          flag = 1
          break
        }
      }
      if (flag == 0){
        tmp_cart.push(item)
      }
    } else {
      // console.log('Starting a new Cart')
      tmp_cart = new Array()
      tmp_cart.push(item);
    }
    this._cart.next(tmp_cart)
    // console.log(tmp_cart)
    this.cartChange()
    this.showCart = true;
  }

  removeItem(sku: string) {
      var tmp_cart = this._cart.getValue()
      if (tmp_cart.length > 0){
        for (let i in tmp_cart){
          if (sku === tmp_cart[i].sku){
            tmp_cart[i].quantity -= 1
            if (tmp_cart[i].quantity == 0) {
              tmp_cart = tmp_cart.slice(0,Number(i)).concat(tmp_cart.slice(Number(i)+1))
            }
            break
          }
        }
      }
      // console.log(tmp_cart)
      this._cart.next(tmp_cart)
      this.cartChange()
    }

  emptyCart() {
    this._cart.next([])
    this.cartChange()
  }

  // <editor-fold> CartChanges: cartChange() updatePrice() updateLink() updateSize()
  private cartChange() {
    // this.setCartCookie()
    this.updatePrice()
    this.updateLink()
    this.updateSize()
  }

  private updatePrice() {
    var price=0
    const tmp_cart = this._cart.getValue()
    const tmp_dic = this._productDic.getValue()
    if(tmp_cart.length > 0) {
      for (let item of tmp_cart) {
        price += (item.quantity * tmp_dic[item.sku].price)
      }
    }
    this._price.next(price);
  }

  private updateLink() {
    var tmp_link = '&products='
    const tmp_cart = this._cart.getValue()
    if(tmp_cart.length > 0) {
      for (let item of tmp_cart){
        tmp_link += item.sku +','+item.quantity +'|'
      }
      tmp_link = tmp_link.slice(0,-1)
    } else { tmp_link = ''}
    this._link.next(tmp_link);
  }

  private updateSize() {
    var tmp_size = 0
    const tmp_cart = this._cart.getValue()
    if(tmp_cart.length > 0) {
      for (let item of tmp_cart){
        tmp_size += item.quantity
      }
    }
    this._size.next(tmp_size);
  }

  // </editor-fold>
}
