import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { first, switchMap } from 'rxjs/operators';
import { Hcp } from '../../core/hcp';
import { Subject, Observable, of } from 'rxjs';


@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  sitenameQuery$: Subject<string|null>;
  uidQuery$: Subject<string|null>;
  emailQuery$: Subject<string|null>;
  pNameQuery$: Subject<string|null>;
  profiles$: Observable<any>;
  sitenames$: Observable<any[]>;
  uids$: Observable<any[]>;
  emails$: Observable<any[]>;
  pnames$: Observable<any[]>;



  constructor(
    public auth: AuthService,
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router,
    private afAuth: AngularFireAuth,
  ) {
    this.sitenameQuery$ = new Subject();
    this.sitenames$ = this.sitenameQuery$.pipe(
      switchMap(sitename => {
        if (sitename) {
        return afs.collection('hcps', ref => ref.where('siteName', '>=', sitename).where('siteName', '<=', sitename+'\uf8ff')).valueChanges()
      } else return of(null)
      })
    );
    this.emailQuery$ = new Subject();
    this.emails$ = this.emailQuery$.pipe(
      switchMap(email => {
        if (email) {
        return afs.collection('hcps', ref => ref.where('email', '>=', email).where('email', '<=', email+'\uf8ff')).valueChanges()
        } else return of(null)
      })
    );
    this.uidQuery$ = new Subject();
    this.uids$ = this.uidQuery$.pipe(
      switchMap(uid => {
        if (uid) {
        return afs.collection('hcps', ref => ref.where('hcp_uid', '>=', uid).where('hcp_uid', '<=', uid+'\uf8ff')).valueChanges()
        } else return of(null)
      })
    );
    this.pNameQuery$ = new Subject();
    this.pnames$ = this.pNameQuery$.pipe(
      switchMap(pname => {
        if (pname) {
        return afs.collection('hcps', ref => ref.where('practiceNameLower', '>=', pname).where('practiceNameLower', '<=', pname+'\uf8ff')).valueChanges()
        } else return of(null)
      })
    );
  }

  ngOnInit() {

    this.auth.user$.subscribe(user => {
      if (user) {
        console.log("user logged in... redirecting...")
        var hcpRef = this.afs.doc<Hcp>(`hcps/${user.hcp_uid}`).valueChanges().subscribe(hcp => {
          this.router.navigateByUrl(`/${hcp.siteName}`);
        }).unsubscribe;
      }
    }).unsubscribe;
  }

  search($event) {
    var queryText = $event.target.value.toLowerCase();
    if (queryText == "") {
      queryText = null
    }
    this.sitenameQuery$.next(queryText);
    this.emailQuery$.next(queryText);
    this.uidQuery$.next(queryText);
    this.pNameQuery$.next(queryText);
  }

  redirect(sitename: string) {
    this.router.navigateByUrl(`/${sitename}`);
  }
}
