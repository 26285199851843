import { Injectable } from '@angular/core';
import { Observable, of, from, BehaviorSubject } from 'rxjs';

export interface SponsorInfo {
  id: string,
}

@Injectable({
  providedIn: 'root'
})
export class SponsorService {
  private _sponsor: BehaviorSubject<SponsorInfo> = new BehaviorSubject(null)
  constructor() { }

  get sponsor() {
    return this._sponsor.asObservable()
  }

  setSponsor(id: string) {
    this._sponsor.next({id: id})
  }
}
