import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cur'
})
export class CurPipe implements PipeTransform {

  transform(value: number, curType?: any): string {
    var curMap = {
      'USD': {
        mutip: 1,
        symbol: '$',
        ender: 'USD',
      },
      'AUD': {
        multip: 1.44,
        symbol: '$',
        ender: 'AUD',
      },
      'NZD': {
        multip: 1.52,
        symbol: '$',
        ender: 'NZD',
      }
    }
    if (curType) {
      return curMap[curType].symbol+(value * curMap[curType].multip).toFixed(2)+'<span class="curType"> '+curMap[curType].ender+'</span>'

    } else {
      return '$'+value
    }

    return null;
  }

}
